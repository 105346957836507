.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* EmailSubscription Component Styles */
.email-subscription {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.email-subscription-image {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.email-subscription-message {
  text-align: center;
  margin-bottom: 30px;
}

.email-subscription-message p {
  font-size: 16px;
  margin-bottom: 15px;
}

.email-subscription-message p:first-child {
  font-size: 18px;
}

.email-subscription-message p:last-child {
  font-weight: bold;
}

.email-subscription-form {
  text-align: center;
  width: 100%;
}

.email-subscription-input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  margin-bottom: 15px;
  box-sizing: border-box;
}

.email-subscription-error {
  color: red;
  font-size: 14px;
  margin: 5px 0;
}

.email-subscription-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #cccccc;
  color: white;
  border: none;
  cursor: not-allowed;
  transition: background-color 0.3s ease;
}

.email-subscription-button:not(:disabled) {
  background-color: #FFA500;  /* オレンジ色 */
  cursor: pointer;
}

.email-subscription-button:not(:disabled):hover {
  background-color: #FF8C00;  /* ダークオレンジ（ホバー時） */
}

/* Footer Styles */
.footer {
  margin-top: auto;
  padding: 20px 0;
  font-size: 14px;
  color: #666;
  text-align: center;
}